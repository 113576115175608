.mapouter{
    position:relative;
    text-align:right;
    height:100%;
    width:100%;
}

#gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:330px;
  width:100%;
  border-radius: 17px;
  background: #800100;
  box-shadow:  17px 17px 40px #6d0100,
              -17px -17px 40px #930100;
}

.Background-Conteudo {
  background: #982819;
}
:root{
  --primary:  #740000;
  --secondary: #982819;
  --tertiary: rgb(233, 233, 233);
}
.text-secondary{
  color: var(--secondary) !important;
}
.bg-secondary{
  background-color: var(--secondary) !important;
}
.text-tertiary{
  color: var(--tertiary) !important;
}
.fundoAgenda{
  background-color: rgb(213, 212, 212);
}
.bg-tertiary{
  background-color: var(--tertiary) !important;
}
#rede1:hover{
  color: #FF1B52;
}
#rede1{
  color: #6d0100;
  padding-right: 30px;
}
#rede2:hover{
  color: #1877F2;
}
#rede2{
  color: #6d0100;
}
.zap{
  color: rgb(37, 37, 37);
}

.cor{
    background-color: rgb(102, 13, 13) !important;
    color: rgb(255, 255, 255);
    background-color: white;
    font-family: sans-serif;
    font-size: 1.5rem;
    background: #FFFFFF;
    border-bottom: 1px solid #ffffff;
    box-shadow: 0px 0px 8px 2px #c9c9c9;
    margin-bottom: 2px;
}

.foto{
    border-radius: 10px 10px 0px 0px;
}




.card {
    background-color: white;
    font-family: sans-serif;
    font-size: 1.5rem;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 2px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 12px 12px 33px #c7c7c7,
        -12px -12px 33px #f9f9f9;

}


.icone{
    font-size:50px;
    color: #B81319;
}
.custom-hr {
    border: 0; /* Remove a borda padrão */
    height: 2px; /* Altura da linha */
    background-color: #000000; /* Cor da linha (vermelho brodô) */
    margin: 1px 0; /* Espaçamento acima e abaixo da linha */
    width: 100%; /* Largura da linha (ajuste conforme necessário) */
    border-radius: 5px; /* Bordas arredondadas */
    box-shadow: 0 0 10px rgba(70, 70, 70, 0.5); /* Sombra (opcional) */
  }
  

.cabecalho{
    margin-bottom: 90px;
}


.texto {
    font-size: 50px !important   ;
    color: #3e3e3f;
  }


.lista {
    font-size: 20px !important;
}

.bordo {
    color: #B81319; /* código de cor para vermelho brodô */
  }
  

.esp{
    margin-bottom: 15px;
}

.corpo {
    margin-top: 120px;
}

.header {
    border-bottom: white;
}
.cor-texto {
  color: #5a5a5a;
  font-weight: bold;
  /* font-size: 10px; */
}

.nav-link.active {
  color: rgb(163, 0, 0) !important;
  position: relative;
  text-decoration: none; /* Remove o sublinhado padrão */
}

.nav-link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 6px; /* Ajuste conforme necessário */
  width: 100%;
  height: 2px; /* Espessura do sublinhado */
  background-color: rgb(163, 0, 0);
}

/* NavA.components.css */

#logo {
  -webkit-transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  width: 60%;
  /* Tamanho padrão */
}

#logo:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Ajustar tamanho da logo para diferentes tamanhos de tela */

@media (min-width: 576px) {
  /* Telas pequenas */
  #logo {
    width: 30%;
  }
}

@media (min-width: 768px) and (max-width: 849px) {
  /* Telas médias */
  #logo {
    width: 100%;
  }
}

@media (min-width: 850px) and (max-width: 991px) {
  /* Telas médias */
  #logo {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Telas grandes */
  #logo {
    width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  /* Telas extra grandes */
  #logo {
    width: 45%;
  }
}


.roter {
  -webkit-transition: -webkit-transform .5s ease;
  transition: transform .1s ease;
  width: 20%;
  margin-top: 3px;
}

.roter:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


#barra {
  font-size: 17px;
}

.menu {
  background-color: white;
  font-family: sans-serif;
  font-size: 1.5rem;
  background: #FFFFFF;
  border-bottom: 1px solid #ffffff;
  box-shadow: 0px 0px 8px 2px #c9c9c9;
  margin-bottom: 2px;

}
.ti {
    color: #740000 !important;
}

.equipe {
    width: 200% !important;
}

@media (min-width: 1200px) {

    /* ajuste o valor conforme necessário */
    .equipe {
        width: 100% !important;
    }
}

@media (min-width: 991px) {
    .equipe {
        width: 100% !important;
    }
}

.fun {
    border-radius: 25px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgb(163, 163, 163) !important;
    color: rgb(255, 255, 255);
 
    font-family: sans-serif;
    font-size: 1.5rem;
   
   
    box-shadow: 0px 0px 8px 2px #c9c9c9;
    margin-bottom: 2px;
}

.carousel-indicators li {
  background-color: gray;
  color: #740000 !important;
}

.carousel-indicators .active {
  background-color: darkgray;
  color: #740000 !important;
}


 .caption1 h3, .caption2 h3, .caption3 h3, .caption4 h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    color: rgb(255, 255, 255); /* Cor principal no centro */
    text-shadow: 
        0 0 15px rgba(0, 0, 0, 0.9),
        0 0 25px rgba(0, 0, 0, 0.7),
        0 0 35px rgba(0, 0, 0, 0.5),
        0 0 50px rgba(0, 0, 0, 0.3);
}

